import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import HistPCF from './HistPCF';
import { openModal } from '../../../../../Library/ModalPopUp';
import Notifications from '../../../Dashboard/Widget/Notifications';
import AxiosLib from '../../../../../Library/AxiosLib';
import moment from 'moment';
import { openToast } from '../../../../../Library/ToastPopUp';
import FinalPCF from './FinalPCF';
import { ButtonPCFIcon, CardTitle } from '../../Components/PCFUI';
import FirstPCFCom from './FirstPCFCom';

export function CurrPCF({ student_status, student_bio, current_acad, refAcad, rereg_data }) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const TabsMenu = [
        { id: 1, name: "History PCF", component: <HistPCF /> },
        { id: 2, name: "PCF Enrollment", component: <FirstPCFCom ref_acad={refAcad} student_status={student_status} student_bio={student_bio} rereg_data={rereg_data} /> },
        { id: 3, name: "Download Final PCF", component: <div className="bg-white border border-top-0"><FinalPCF students={student_status} /></div> }
    ];

    const [tabsActive, setTabActive] = useState((rereg_data && Object.values(rereg_data.data).length >0) ? 1 : 0);

    const fetchingAnnouncements = (param) => {
        AxiosLib(param, "mc/api/notifications/fetch").then(
            (response) => {
                if (response.error) {
                    openToast({ header: "Error", message: response.error })
                } else {
                    if (response.data) {
                        openModal({ header: "Information", message: <BodyNotif data={response.data} />, size: "lg" })
                    }
                }
            }
        );
    }
    useEffect(() => {
        fetchingAnnouncements({ "RoleID": AuthDecode.currentData.roleid, "MenuID": 5, "CurrentDate": moment().format("YYYY-MM-DD") })
    }, [])

    return (
        <div id='current-pcf'>
            <CardTitle icon={"jangkar"} message={"Plan Course Form Term "+ (refAcad?.name || "-") } is_open={rereg_data ? true : false} />
            <div className="tabulasi-pcf">
                {(Object.values(student_status).length > 0) && (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="tab-header d-flex justify-content-start align-items-center">
                                {TabsMenu.map((item, index) => (
                                    <div key={item.id} className={'border border-bottom-0 rounded-top p-5 ' + ((index === tabsActive) ? "bg-white" : "bg-light cursor-pointer")} onClick={() => setTabActive(index)}>
                                        <span className={(index === tabsActive) ? "font-weight-bolder" : ""}>
                                            {item.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {tabsActive !== 1 && (
                                    <ButtonPCFIcon title={"PCF Revision"} url={"/registration/revision-plan-course-form"} />
                                )}
                            </div>
                        </div>
                        <div className="tab-body">
                            {TabsMenu[tabsActive].component}
                        </div>
                        <div className="mt-8">
                            <Notifications stdstatus={student_status} exept={1} ispopup={false} />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

const BodyNotif = ({ data }) => {
    return (
        <div>
            {data.map((v, index) => (
                <div className="" key={index}>
                    <h3>{v.Title}</h3>
                    <div style={{ overflow: "auto", minWidth: "200px" }}>
                        <div dangerouslySetInnerHTML={{ __html: v.Message }} />
                    </div>
                </div>
            ))}
        </div>
    )
}