import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { REQUEST_TARGET_MODULES } from "../../../Library/APIsTarget";
import LoadingBtn from "../../Widgets/LoadingBtn";
import { CurrPCF, ShortSemester } from "./index";
import { CardMessage } from "./Components/PCFUI";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export function PlanCourseFormCom() {
  const studentid = AuthDecode.currentData.entityid;
  const acad_type_short_semes = "asc.atp.type.term.short";

  const [stdStatus, setStdStatus] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [stdBio, setStdBio] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [stdAcad, setStdAcad] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [refAcad, setRefAcad] = useState();

  const [rereg, setRereg] = useState({ loading: false, data: [], message: [] });

  const GetSelectedAcad = async (acadType) => {
    setRefAcad(null);
    setStdAcad({ loading: true, data: [], message: "" });
    const results = await REQUEST_TARGET_MODULES({
      target_data: "GET_ACTIVE_ACAD",
    });
    console.log(results);
    if (results.data && Object.values(results.data).length > 0) {
      const findNotNull = results.data.filter(
        (item) => item.atp_group_type !== null
      );
      console.log(findNotNull);
      const selectIndex = findNotNull.findIndex(
        (v) => v.atp_group_type.id === acadType
      );
      console.log(selectIndex);
      setRefAcad(
        findNotNull[selectIndex]
          ? findNotNull[selectIndex]?.reff_atp_term
          : null
      );
      setStdAcad({ loading: false, data: results.data, message: "" });
    } else {
      setRefAcad(null);
      setStdAcad({
        loading: false,
        data: [],
        message: "No active Academic Year found",
      });
    }
  };

  const GetDataStudentStatus = async () => {
    setStdStatus({ loading: true, data: [], message: "" });
    const param = {
      parameters: {
        studentid: studentid,
      },
      target_data: "GET_STUDENT_STATUS",
    };
    const results = await REQUEST_TARGET_MODULES(param);
    if (results.data && Object.values(results.data).length > 0) {
      const atp_group_type = results.data?.atp_group_type || "";
      console.log(atp_group_type);
      GetSelectedAcad(atp_group_type);
      setStdStatus(results);
    } else {
      setStdStatus({
        loading: false,
        data: [],
        message: "No record found",
      });
    }
  };

  const GetDataStudentBio = async () => {
    setStdBio({ loading: true, data: [], message: "" });
    const param = {
      parameters: {
        studentid: studentid,
      },
      target_data: "GET_STUDENT_DATA_M",
    };
    const results = await REQUEST_TARGET_MODULES(param);
    if (results.data && Object.values(results.data).length > 0) {
      setStdBio(results);
    } else {
      setStdBio({
        loading: false,
        data: [],
        message: "No record found",
      });
    }
  };

  const GetReregActive = async () => {
    setRereg({ loading: true, data: [], message: "" });
    const param = {
      parameters: { studentid: studentid },
      target_data: "GET_REREG_STUDENT",
    };
    const results = await REQUEST_TARGET_MODULES(param);
    setRereg(results);
  };

  useEffect(() => {
    GetDataStudentStatus();
    GetDataStudentBio();
    GetReregActive();
  }, []);

  if (stdStatus.loading || stdBio.loading || stdAcad.loading) {
    return <LoadingBtn />;
  } else if (stdStatus.message || stdBio.message || stdAcad.message) {
    return <CardMessage message={stdStatus.message || stdAcad.message} icon={"info"} />;
  } else if (Object.values(stdAcad.data).length > 0 && refAcad) {
    if (stdStatus.data && stdStatus.data.is_active === 1) {
      if (Object.values(rereg.data).length > 0) {
        if (refAcad.atp_type === acad_type_short_semes) {
          return <ShortSemester student_status={stdStatus.data} student_bio={stdBio.data} refAcad={refAcad} data_pcf={rereg.data} />;
        } else {
          return <CurrPCF student_status={stdStatus.data} student_bio={stdBio.data} current_acad={stdAcad.data} refAcad={refAcad} rereg_data={rereg} />;
        }
      } else {
        return <CurrPCF student_status={stdStatus.data} student_bio={stdBio.data} current_acad={stdAcad.data} refAcad={refAcad} rereg_data={rereg} />;
      }
    } else {
      return <CardMessage message={"You cannot access this page. Status student is " + (stdStatus.data?.status?.name || "Unknown")} icon={"box"} />;
    }
  } else {
    return <CardMessage message={"Not in re-registration periode"} icon={"box"} />;
  }
}


