import React, { useState } from 'react'
import CardProfilePCF from '../../Components/CardProfilePCF';
import FormPCF from './FormPCF';
import PCFPreview from './PCFPreview';
import { CardMessage } from '../../Components/PCFUI';

export default function FirstPCFCom({ student_status, student_bio, ref_acad, rereg_data }) {
    const studentid = student_status.studentid;
    const [prevPCF, setPrevPCF] = useState({ pcf: [], data: [], param: [], totalsks: 0, open: false });
    return (
        <div id='first-pcf'>
            <div className={prevPCF.open ? "d-none" : "d-block"}>
                <CardProfilePCF studentid={studentid} student_status={student_status} student_bio={student_bio} />
                
                {(rereg_data && rereg_data.message) ? (
                    <CardMessage message={rereg_data.message} icon={"box"} />
                ) : (
                    <FormPCF data={rereg_data.data} student={student_status} ref_acad={ref_acad} setPrevPCF={setPrevPCF} />
                )}                
            </div>
            <div className={prevPCF.open ? "d-block" : "d-none"}>
                <PCFPreview data_prev={prevPCF} setPrevPCF={setPrevPCF} student_bio={student_bio} studentid={studentid} />
            </div>
        </div>
    )
}
