import React, { useState } from 'react'
import jwt_decode from "jwt-decode";
import { FormatRupiah, StudentIDFormat } from '../../../../../Library/GlobalHelper'
import { openModal } from '../../../../../Library/ModalPopUp';
import { openToast } from '../../../../../Library/ToastPopUp';
import { REQUEST_TARGET_MODULES } from '../../../../../Library/APIsTarget';

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function PCFPreview({ data_prev, setPrevPCF, student_bio, studentid }) {
    const { pcf, data, param, totalsks } = data_prev
    const [submitPCF, setSubmitPCF] = useState({ loading: false, data: [], message: "" });
    const SubmitPreviewPCF = async () => {
        if (AuthDecode.remotedBy) {
            openToast({ header: "Error", message: "You cannot access this request" });
        } else {
            setSubmitPCF({loading:true, data:[], message:""})
            const param_post = {
                parameters: param,
                target_data: "SUBMIT_REREG_PCF",
            }

            const results = await REQUEST_TARGET_MODULES(param_post);
            if (results.message) {
                openToast({
                    message: results.message,
                    header: "Error",
                });
            } else {
                openToast({
                    message: "Successfully submit PCF",
                    header: "Info",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
            setSubmitPCF(results);
        }
    }

    return (
        <div className="card card-custom">
            <div className="card-body p-0">
                <HeaderPrev fullname={student_bio.fullname} studentid={studentid} />

                <div className="previous-pcf">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr className="font-weight-boldest h-65px" style={{ backgroundColor: "#474bcf" }}>
                                    <th className="align-middle font-size-h4 text-white border-0" width={"5%"}>No</th>
                                    <th className="align-middle font-size-h4 text-white border-0">Course</th>
                                    <th className="align-middle font-size-h4 text-white border-0">Type/Credit</th>
                                    <th className="align-middle font-size-h4 text-white border-0">Section ID</th>
                                    <th className="align-middle font-size-h4 text-white border-0">Lecturers</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(pcf).length > 0 ? (
                                    pcf.map((v, index) => (
                                        <tr key={index} className="font-size-lg font-weight-bolder h-65px">
                                            <td>{index + 1}</td>
                                            <td>
                                                <span className="d-block font-weight-bolder">
                                                    {v.detail.coursetitle ? v.detail.coursetitle : ""}
                                                </span>
                                                <span className="text-muted font-weight-bolder">
                                                    {v.detail.course_code ? v.detail.course_code : "-"}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="d-block text-capitalize font-weight-bolder">
                                                    Credit {v.detail.course_credit ? v.detail.course_credit : ""} SKS
                                                </span>
                                                <span className="text-muted text-capitalize font-weight-bolder">
                                                    {v.detail.course_type ? v.detail.course_type : ""}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="font-weight-bolder">
                                                    {v.detail.section_id ? v.detail.section_id : ""}
                                                </span>
                                            </td>
                                            <td>
                                                {Object.values(v.detail.lecturer).length > 0 ? (
                                                    v.detail.lecturer.map((l, index) => (
                                                        <span className="d-block text-dark" key={index}>
                                                            {l.emp ? l.emp.fullname : "-"}
                                                        </span>
                                                    ))
                                                ) : ""}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5}>No record found</td>
                                    </tr>
                                )}
                            </tbody>
                            {totalsks > 0 && (
                                <tfoot>
                                    <tr>
                                        <td colSpan={2} className="font-weight-bolder text-right">Total Credit:</td>
                                        <td className="font-weight-bolder">{totalsks} SKS</td>
                                        <td colSpan={2}></td>
                                    </tr>
                                    {(data && Object.values(data).length > 0) && (
                                        <tr>
                                            <td colSpan={2} className="font-weight-bolder text-right">Limit Credit:</td>
                                            <td className="font-weight-bolder">{data.max_sks} SKS</td>
                                            <td colSpan={2}></td>
                                        </tr>
                                    )}
                                </tfoot>
                            )}
                        </table>
                    </div>
                </div>

                <DetailAmount prevamount={data} />

                <AlertAccess />

                <div className="text-center my-10">
                    <button className="btn btn-lg btn-light mr-5 font-weight-bold text-uppercase px-7" type="button" onClick={() => openModal({ header: "", message: <ModalCancel action={setPrevPCF} /> })}>Cancel</button>
                    <button className="btn btn-lg btn-primary font-weight-bold text-uppercase px-7" type="button" onClick={() => SubmitPreviewPCF()} disabled={submitPCF.loading}>{submitPCF.loading ? "Processing..." : "Submit and Finish"}</button>
                </div>
            </div>
        </div>
    )
}

const ModalCancel = ({ action }) => {
    return (
        <div className="confirm-close">
            <h3>Are you sure want to cancel the PCF ?</h3>
            <p className="font-weight-bolder">You will permanently cancel this payment.</p>

            <div className="text-right pt-10">
                <button className="btn btn-lg btn-clear px-6 font-weight-bolder" type="button" onClick={() => openModal({ open: false })}>
                    Not Now
                </button>
                <button className="btn btn-lg btn-danger px-6 font-weight-bolder" type="button" onClick={() => { action({ pcf: [], data: [], param: [], totalsks: 0, open: false }); openModal({ open: false }) }}>
                    Cancel Registration
                </button>
            </div>
        </div>
    )
}

const HeaderPrev = ({ fullname, studentid }) => {
    return (
        <div className="header-info">
            <div className="row justify-content-center py-8">
                <div className="col-md-10">
                    <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                        <div className="d-flex flex-column px-0 order-2 order-md-1 align-items-center align-items-md-start">
                            <span className="h1 font-weight-boldest">{fullname}</span>
                            <span className="d-flex flex-column font-size-h5 text-muted align-items-center align-items-md-start">
                                <span>{StudentIDFormat(studentid)}</span>
                            </span>
                        </div>
                        <div className="order-1 order-md-2 mb-5 mb-md-0 text-right">
                            <span className="d-block text-uppercase h1 font-weight-boldest">PCF Preview</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AlertAccess = () => {
    if (AuthDecode.remotedBy) {
        return (
            <div className="alert alert-danger">
                <div className="alert-title text-left">
                    <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                    You cannot submit this PCF.
                    <br />
                    This PCF belongs to{" "}
                    <b>
                        Mr/Mrs. {AuthDecode.currentData.given_name +
                            " " +
                            AuthDecode.currentData.family_name}
                    </b>
                </div>
            </div>
        )
    }else{
        return "";
    }
}

const DetailAmount = ({ prevamount }) => {
    return (
        <div className="prev-amount">
            {(prevamount && Object.values(prevamount).length > 0) && (
                <div className="tuition-fee">
                    <div className="d-flex flex-column px-0 order-2 order-md-1 align-items-center">
                        <span className="h1 font-weight-boldest">Draft Invoice</span>
                        <span className="d-flex flex-column font-size-h5 text-muted align-items-center align-items-md-start">
                            <span>(before scholarship and adjustment)</span>
                        </span>
                    </div>
                    {Object.values(prevamount.itemDetail).length > 0 && (
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr className="font-weight-boldest h-65px" style={{ backgroundColor: "#474bcf" }}>
                                        <th className="align-middle font-size-h4 text-white border-0">Name</th>
                                        <th className="align-middle font-size-h4 text-white border-0">QTY</th>
                                        <th className="align-middle font-size-h4 text-white border-0">Price</th>
                                        <th className="align-middle font-size-h4 text-white border-0">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {prevamount.itemDetail.map((v, index) => (
                                        <tr key={index} className="font-size-lg font-weight-bolder">
                                            <td className="font-size-h4">{v.itemName}</td>
                                            <td className="font-size-h4">{v.itemQty}</td>
                                            <td className="font-size-h4">IDR {FormatRupiah(v.itemPrice)}</td>
                                            <td className="font-size-h4">IDR {FormatRupiah(v.itemQty * v.itemPrice)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                {prevamount.totalAmount ? (
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5} className="text-center py-5">
                                                <span className="font-weight-bolder font-size-h1">
                                                    Total Payment: IDR {FormatRupiah(prevamount.totalAmount)}
                                                </span>
                                            </td>
                                        </tr>
                                    </tfoot>
                                ) : ""}
                            </table>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}